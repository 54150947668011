import {httpDelete, httpGet, httpPost, httpPut} from '@/plugins/request'


/**
 * 获取列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectPersons(query = [])
{
    return httpGet('/manage/project/person', query)
}

/**
 * 新增收款人
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiStoreProjectPerson(data)
{
    return httpPost('/manage/project/person', data)
}

/**
 * 修改收款人
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateProjectPerson(id, data)
{
    return httpPut(`/manage/project/person/${id}`, data)
}


/**
 * 删除收款人
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiDestroyProjectPerson(id)
{
    return httpDelete(`/manage/project/person/${id}`)
}
