import {httpDelete, httpGet, httpPost, httpPut} from '@/plugins/request'
import {getApiUrl} from "@/plugins/common"
import {getAccessToken} from '@/plugins/auth'
/**
 * 获取收支列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectCosts(query = [])
{
    return httpGet('/manage/project/cost', query)
}

/**
 * 新增收支
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiStoreProjectCosts(data)
{
    return httpPost('/manage/project/cost', data)
}

/**
 * 修改收支
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateProjectCost(id, data)
{
    return httpPut(`/manage/project/cost/${id}`, data)
}

/**
 * 删除收支项
 *
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiDestroyProjectCost(id, query)
{
    return httpDelete(`/manage/project/cost/${id}`, query)
}

/**
 * 增加导出功能
 * @param query
 * @returns {string}
 */
export function apiExportProjectCosts(query) {
    query.token = getAccessToken()
    return getApiUrl('/manage/project/cost/data/export', query)
}

/**
 * 同步企微记录
 * @param data
 * @returns {Promise<*>}
 */
export function apiSyncProjectCost(data)
{
    return httpPost('/manage/project/cost/data/sync', data)
}
