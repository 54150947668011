<template>
  <div>
    <!----- 搜索框 start------->
    <el-form :inline="true" class="search">
      <el-form-item label="合同">
        <el-select
            v-model="searchData.project_contract_id"
            filterable
            width="250"
            clearable>
          <el-option
              v-for="(item, index) in contract"
              :key="index"
              :label="item.project.name + item.remark + '「' + item.start_at + '~' + item.end_at + '」'"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="searchData.type" style="width:150px;" clearable>
          <el-option label="支出" :value="2"></el-option>
          <el-option label="收入" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchData.status" style="width:150px;" clearable>
          <el-option label="已审" :value="2"></el-option>
          <el-option label="待审" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账户">
        <el-select v-model="searchData.pay_type" style="width:150px;" clearable>
          <el-option label="未付" :value="0"></el-option>
          <el-option label="公账" :value="1"></el-option>
          <el-option label="私账" :value="2"></el-option>
          <el-option label="支付宝" :value="3"></el-option>
          <el-option label="X私账" :value="4"></el-option>
          <el-option label="储值" :value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
            v-model="searchData.pay_at"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item label="预算关键字">
        <el-input v-model="searchData.budget_keyword" clearable placeholder="预算关键字"/>
      </el-form-item>
      <el-form-item label="关键字">
        <el-input v-model="searchData.keyword" clearable placeholder="关键字"/>
      </el-form-item>
      <el-form-item label="金额">
        <el-input v-model="searchData.price" placeholder="金额"/>
      </el-form-item>
      <el-form-item>
        <el-button @click="getCosts()">查询</el-button>
        <el-button @click="exportCosts()">导出</el-button>
      </el-form-item>

      <el-form-item style="float: right">
        <el-input style="width: 180px;margin-right:5px;" v-model="syncForm.data.sp_no" placeholder="企微单号">
          <template #append>
            <el-button @click="clickSync()">同步</el-button>
          </template>
        </el-input>
        <el-button type="primary" @click.stop="clickCreate()">添加支出</el-button>
      </el-form-item>
    </el-form>
    <!----- 搜索框 end------->

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="pay_at" label="付款时间"/>
      <el-table-column prop="type_desc" label="类型"/>
      <el-table-column prop="pay_type_desc" label="账户"/>
      <el-table-column prop="price" label="金额"/>
      <el-table-column prop="remark" label="备注"/>
      <el-table-column prop="contract.name" label="项目" />
      <el-table-column prop="budget.name" label="预算名"></el-table-column>
      <el-table-column label="收款人">
          <template v-slot="scope">
            <div>{{ scope.row.person.name }}</div>
            <div>{{ scope.row.person.account }}</div>
          </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="clickUpdate(scope.row)">修改</el-button>
          <el-button type="success" size="small" @click="clickDestroy(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!----- 表格 end------->


    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          @update:current-page="getCosts()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="编辑收支" fullscreen append-to-body>
      <el-form
          ref="cost"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"
      >
        <el-form-item label="合同" prop="project_contract_id">
          <el-select v-model="formData.data.project_contract_id" filterable @change="getProjectBudget">
            <el-option label="无合同" :value="0"></el-option>
            <el-option
                v-for="(item, key) in contract"
                :key="key"
                :label="item.project.name + item.remark + '「' +item.start_at + '~' + item.end_at + '」'"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预算" filterable prop="project_budget_id">
          <el-select v-model="formData.data.project_budget_id" filterable>
            <el-option label="无预算" :value="0"></el-option>
            <el-option
                v-for="(item, key) in budget"
                :key="key"
                :label="item.parent.name + ' - ' +item.name + '「' + item.amount + '」'"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间" prop="pay_at">
          <el-date-picker v-model="formData.data.pay_at" value-format="YYYY-MM-DD" type="date"
                          placeholder="付款时间"/>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="formData.data.type">
            <el-option label="支出" :value="2"></el-option>
            <el-option label="收入" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额" prop="price">
          <el-input v-model="formData.data.price" placeholder="金额"/>
        </el-form-item>
        <el-form-item label="说明" prop="remark">
          <el-input type="textarea" :rows="3" v-model="formData.data.remark" placeholder="说明信息"/>
        </el-form-item>
        <el-form-item label="收款人" prop="person_id">
          <el-select v-model="formData.data.person_id" filterable>
            <el-option label="无" :value="0"></el-option>
            <el-option
                v-for="(item, key) in person"
                :key="key"
                :label="item.name + ' ' + item.remark + ' 「' + item.account + '」'"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款方式" prop="pay_type">
          <el-select v-model="formData.data.pay_type">
            <el-option label="未付" :value="0"></el-option>
            <el-option label="公账" :value="1"></el-option>
            <el-option label="私账" :value="2"></el-option>
            <el-option label="企业支付宝" :value="3"></el-option>
            <el-option label="谢私" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="formData.data.status">
            <el-option label="已审核" :value="2"></el-option>
            <el-option label="审核中" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
import {
  apiGetProjectCosts,
  apiStoreProjectCosts,
  apiUpdateProjectCost,
  apiDestroyProjectCost,
  apiExportProjectCosts,
  apiSyncProjectCost
} from '@/api/projectCostApi'

import {getCompanyId} from "@/plugins/company"
import {apiGetProjectContracts} from '@/api/projectContractApi'
import {apiGetProjectBudgets} from '@/api/projectBudgetApi'
import {apiGetProjectPersons} from '@/api/projectPersonApi'

let formData = {
  company_id: getCompanyId(),
  project_contract_id: 0,
  project_budget_id: 0,
  type: 2,
  price: '',
  remark: '',
  person_id: 0,
  serial_no: '',
  status: 2,
  pay_type: 1,
  pay_at: '',
  impress_id: 0,
  budget_keyword: '',
}

export default {
  name: 'project.cost',
  created() {
    this.getCosts()
    this.getProjectContract()
    this.getProjectPerson()
  },
  data() {
    return {
      contract: [],
      budget: [],
      person: [],
      syncForm: {
        data: {
          sp_no: '',
          company_id: getCompanyId(),
        },
        show: false,
      },
      // 创建表单
      formData: {
        lock: false,
        data: formData,
        rules: {
          remark: [{
            required: true,
            message: '备注信息',
            trigger: 'blur',
          }],
          price: [{
            required: true,
            message: '请填写金额',
            trigger: 'blur',
          }],
          project_contract_id: [{
            required: true,
            message: '请选择合同',
            trigger: 'blur',
          }],
          project_budget_id: [{
            required: true,
            message: '请选择预算',
            trigger: 'blur',
          }],
          person_id: [{
            required: true,
            message: '请选择收款人',
            trigger: 'blur',
          }],
          type: [{
            required: true,
            message: '请选择类型',
            trigger: 'blur',
          }],
          pay_type: [{
            required: true,
            message: '请选择付款方式',
            trigger: 'blur',
          }],
          status: [{
            required: true,
            message: '请选择状态',
            trigger: 'blur',
          }],
          pay_at: [{
            required: true,
            message: '请选择时间',
            trigger: 'blur',
          }],
        },
        show: false,
      },
      tableData: [],
      searchData: {
        company_id: getCompanyId(),
        keyword: '',
        type: '',
        pay_type: '',
        pay_at: '',
        project_contract_id: '',
        status: 2,
        page: 1,
        price: '',
        budget_keyword: '',
      },
      pageData: {
        total: 0,
      }
    }
  },
  methods: {
    getCosts() {
      apiGetProjectCosts(this.searchData).then((res) => {
        this.tableData = res.data
        this.pageData = res.meta
      })
    },
    exportCosts () {
      window.open(apiExportProjectCosts(this.searchData))
    },
    getProjectContract() {
      apiGetProjectContracts({per_page: 500, with_query: ['project'], 'status': 1, company_id: getCompanyId(),}).then(res => {
        this.contract = res.data
      })
    },
    getProjectBudget(id) {
      this.budget = []
      if (id === 0) {
        return
      }
      apiGetProjectBudgets({
        company_id: getCompanyId(),
        project_contract_id: id,
        per_page: 500,
        type_parent_id: 'gt',
        parent_id: 0,
        with_query: ['parent'],
      }).then(res => {
        this.budget = res.data
      })
    },
    getProjectPerson() {
      apiGetProjectPersons({per_page: 500, company_id: getCompanyId(),}).then(res => {
        this.person = res.data
      })
    },
    onSubmit() {
      if (this.formData.lock) {
        return;
      }
      this.formLock()
      this.$refs['cost'].validate(valid => {
        if (valid) {
          // 更新逻辑
          if (this.formData.data.id > 0) {
            apiUpdateProjectCost(this.formData.data.id, this.formData.data).then(() => {
              this.$message.success('修改成功')
              this.getCosts()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          } else {
            apiStoreProjectCosts(this.formData.data).then(() => {
              this.$message.success('创建成功')
              this.getCosts()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          }
        }
      })
    },
    clickSync () {
      apiSyncProjectCost(this.syncForm.data).then(() =>{
        this.$message.success('操作成功')
        this.syncForm.show = false
        this.syncForm.data.sp_no = ''
      })
    },
    // 点击更新
    clickUpdate(row) {
      this.getProjectBudget(row.project_contract_id)
      this.formData.data = row
      // 默认都为已审核
      this.formData.data.status = 2
      this.showDialog()
    },
    clickDestroy(row) {
      this.$confirm('确定删除吗').then(() => {
        apiDestroyProjectCost(row.id).then(() => {
          this.getPersons()
          this.$message.success('删除成功')
        })
      })
    },
    // 点击创建
    clickCreate() {
      this.initDialog()
      this.showDialog()
    },
    formLock() {
      this.formData.lock = true
    },
    formUnlock() {
      this.formData.lock = false
    },
    // 初始化弹出
    initDialog() {
      this.formData.data = formData
      this.closeDialog()
    },
    // 显示弹窗
    showDialog() {
      this.formData.show = true
    },
    // 关闭弹窗
    closeDialog() {
      this.formData.show = false
    }
  }
}
</script>
